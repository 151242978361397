import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'
import HomePage from '@/pages/HomePage'
import SettingsPage from '@/pages/SettingsPage'
import { PATH_NAME } from './paths'

const INTERNAL_ROUTES: RouteObject[] = [
  {
    path: PATH_NAME.HOME,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <HomePage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.SETTINGS,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SettingsPage />
      </Suspense>
    )
  },
  {
    path: PATH_NAME.TERMS,
    element: <div>Terms</div>
  },
  {
    path: PATH_NAME.PRIVACY,
    element: <div>Privacy</div>
  }
]

export { INTERNAL_ROUTES }
