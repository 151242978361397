import { Authenticator } from '@aws-amplify/ui-react'
import { fetchUserAttributes, FetchUserAttributesOutput, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { createContext, useEffect, useState } from 'react'

export const AuthContext = createContext<{
  uId: string
  email: string
  firstName: string
  lastName: string
  birthdate: string
} | null>(null)

export function AuthContextProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [userAttributes, setUserAttributes] = useState<FetchUserAttributesOutput>()

  const getUserAttributes = async () => {
    const user = await getCurrentUser()
    console.log(user)
    try {
      const userAttributes = await fetchUserAttributes()
      setUserAttributes(userAttributes)
      localStorage.setItem('userAttributes', JSON.stringify(userAttributes))
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const storedUserAttributes = localStorage.getItem('userAttributes')
    async function authSession() {
      const session = await fetchAuthSession()
      if (session.userSub) {
        getUserAttributes()
      }
    }
    if (!storedUserAttributes) {
      authSession()
    } else {
      setUserAttributes(JSON.parse(storedUserAttributes))
    }

    // Subscribe to auth events
    Hub.listen('auth', ({ payload }) => {
      console.log(`A new auth event has happened: ${payload.event}`)
      switch (payload.event) {
        case 'signedIn':
          getUserAttributes()
          break
        case 'signedOut':
          localStorage.removeItem('userAttributes')
          break
        default:
          break
      }
    })
  }, [])

  const value = userAttributes
    ? {
        uId: userAttributes.sub!,
        email: userAttributes.email!,
        firstName: userAttributes.given_name!,
        lastName: userAttributes.family_name!,
        birthdate: userAttributes.birthdate!
      }
    : null

  return (
    <AuthContext.Provider value={value}>
      <Authenticator socialProviders={import.meta.env.MODE === 'development' ? ['google'] : []}>
        {children}
      </Authenticator>
    </AuthContext.Provider>
  )
}
