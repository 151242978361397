export const PATH_NAME = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  DASHBOARD: '/dashboard',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  WORKOUTS: '/workouts',
  WORKOUT: '/workout',
  WORKOUT_CREATE: '/workout/create',
  WORKOUT_EDIT: '/workout/edit',
  WORKOUT_DELETE: '/workout/delete',
  EXERCISES: '/exercises',
  EXERCISE: '/exercise',
  EXERCISE_CREATE: '/exercise/create',
  EXERCISE_EDIT: '/exercise/edit',
  EXERCISE_DELETE: '/exercise/delete',
  ROUTINES: '/routines',
  ROUTINE: '/routine',
  ROUTINE_CREATE: '/routine/create',
  ROUTINE_EDIT: '/routine/edit',
  ROUTINE_DELETE: '/routine/delete',
  MEASUREMENTS: '/measurements',
  MEASUREMENT: '/measurement',
  MEASUREMENT_CREATE: '/measurement/create',
  MEASUREMENT_EDIT: '/measurement/edit',
  MEASUREMENT_DELETE: '/measurement/delete',
  NUTRITION: '/nutrition',
  NUTRITION_CREATE: '/nutrition/create',
  NUTRITION_EDIT: '/nutrition/edit',
  NUTRITION_DELETE: '/nutrition/delete',
  NUTRITION_LOG: '/nutrition/log',
  NUTRITION_LOG_CREATE: '/nutrition/log/create',
  NUTRITION_LOG_EDIT: '/nutrition/log/edit',
  NUTRITION_LOG_DELETE: '/nutrition/log/delete',
  NUTRITION_PLAN: '/nutrition/plan',
  NUTRITION_PLAN_CREATE: '/nutrition/plan/create',
  NUTRITION_PLAN_EDIT: '/nutrition/plan/edit',
  NUTRITION_PLAN_DELETE: '/nutrition/plan/delete',
  NUTRITION_RECIPE: '/nutrition/recipe',
  NUTRITION_RECIPE_CREATE: '/nutrition/recipe/create',
  NUTRITION_RECIPE_EDIT: '/nutrition/recipe/edit',
  NUTRITION_RECIPE_DELETE: '/nutrition/recipe/delete',
  NUTRITION_RECIPE_LOG: '/nutrition/recipe/log',
  NUTRITION_RECIPE_LOG_CREATE: '/nutrition/recipe/log/create',
  NUTRITION_RECIPE_LOG_EDIT: '/nutrition/recipe/log/edit',
  NUTRITION_RECIPE_LOG_DELETE: '/nutrition/recipe/log/delete'
}
