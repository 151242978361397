import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import type { Schema } from '../../amplify/data/resource'
import { generateClient } from 'aws-amplify/data'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const client = generateClient<Schema>()
