import { MainNavigation } from '@/components/MainNavigation'
import { captureException } from '@sentry/react'

const HomePage: React.FC = () => {
  console.log('home page')

  return (
    <main className="relative overflow-hidden text-white">
      <div className={`fixed left-0 right-0 top-0 h-20 bg-gray-900 p-6`}></div>
      <div className="my-20 h-[calc(100vh-160px)] overflow-y-auto bg-gray-900">
        <div className={`px-6`}>
          home page
          <button onClick={() => captureException('Break the world')}>Break the world</button>
        </div>
      </div>
      <MainNavigation />
    </main>
  )
}

export default HomePage
