import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { INTERNAL_ROUTES } from './routes/internalRoutes.tsx'

const router = createBrowserRouter(INTERNAL_ROUTES)

export function App() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  )
}
