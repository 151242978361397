import { MainNavigation } from '@/components/MainNavigation'
import { Button } from '@/components/ui/button'
import { signOut, deleteUser } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { client } from '@/lib/utils'
import type { Schema } from '../../amplify/data/resource'
import _ from 'lodash'

const fetchUserMeals = async () => {
  const { data: UserMeals } = await client.models.UserMeals.list({
    selectionSet: ['id', 'name', 'enabled', 'displayOrder', 'owner']
  })
  return UserMeals
}

const SettingsPage: React.FC = () => {
  const [userMeals, setUserMeals] = useState<Array<Schema['UserMeals']['type']>>([])

  useEffect(() => {
    fetchUserMeals().then((fetchedMeals) => setUserMeals(_.sortBy(fetchedMeals, 'displayOrder')))
  }, [])

  const deleteAccount = async () => {
    // await client.models.UserProfile.delete({ id: '1' })
    await deleteUser() // Implement delete account functionality here
  }
  return (
    <main className="relative overflow-hidden text-white">
      <div className={`fixed left-0 right-0 top-0 h-20 bg-gray-900 p-6`}></div>
      <div className="my-20 h-[calc(100vh-160px)] overflow-y-auto bg-gray-900">
        <div className={`px-6`}>
          {userMeals.map((meal) => (
            <div key={meal.id}>
              {meal.name} | {meal.enabled.toString()}
            </div>
          ))}
          <Button variant="destructive" onClick={deleteAccount}>
            Delete Account
          </Button>
        </div>
        <Button onClick={() => signOut()}>Sign Out</Button>
      </div>
      <MainNavigation />
    </main>
  )
}

export default SettingsPage
